import React, { Suspense, lazy } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';

import './App.css';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import { useAuth } from './context/auth';
const Question = lazy(() => import('./components/Question'));
const ShowResult = lazy(() => import('./components/ShowResult'));
const Admin = lazy(() => import('./components/Admin'));
const Error = lazy(() => import('./components/Error'));
const ExamList = lazy(() => import('./components/ExamList'));
const LoginWithPhone = lazy(() => import('./components/LoginWithPhone'));
const Register = lazy(() => import('./components/Register'));
const ResultShow = lazy(() => import('./components/ResultShow'));
const Spinner = lazy(() => import('./components/Spinner'));
const Home = lazy(() => import('./components/Home'));
const Login = lazy(() => import('./components/Login'));

function App() {
  const [auth, setauth] = useAuth();

  const search = useLocation().search;

  const id = new URLSearchParams(search).get('id');

  const exam_type = new URLSearchParams(search).get('exam-type');

  return (
    <>
      <ToastContainer />
      <Suspense>
        <Routes>
          <Route
            exact
            path={auth.token || id ? '/' : '/n'}
            element={<Login />}
          ></Route>
          <Route
            exact
            path={auth.token ? '/home' : '/n'}
            element={<Home />}
          ></Route>
          {/* <Route path="/" element={<PrivateRoute />}> */}
          {/* <Route path="/home" element={<Home />}></Route> */}
          <Route path={`/question`} element={<Question />}></Route>
          <Route path="/result_show" element={<ResultShow />}></Route>
          <Route path="/result" element={<ShowResult />}></Route>
          <Route path="/admin" element={<Admin />}></Route>
          <Route path="/login" element={<LoginWithPhone />} />
          <Route path="/*" element={<Error />} />
          <Route path="/spinner" element={<Spinner />} />
          <Route path="/register" element={<Register />} />
          <Route path="/exams" exact element={<ExamList />} />

          {/* </Route> */}
        </Routes>
      </Suspense>
    </>
    // <>

    //   <ToastContainer />

    //   <Suspense>
    //     <Routes>

    //       <Route

    //         exact

    //         path={auth.token || id ? "/" : "/n"}

    //         element={<Login />}

    //       ></Route>

    //       <Route

    //         exact

    //         path={auth.token ? "/home" : "/n"}

    //         element={<Home />}

    //       ></Route>

    //       {/* <Route path="/" element={<PrivateRoute />}> */}

    //       <Route path="/result_show" element={<ResultShow />}></Route>

    //       <Route path={`/question`} element={<Question />}></Route>

    //       <Route path="/result" element={<ShowResult />}></Route>

    //       <Route path="/admin" element={<Admin />}></Route>

    //       <Route path="/login" element={<LoginWithPhone />} />

    //       <Route path="/*" element={<Error />} />

    //       <Route path="/spinner" element={<Spinner />} />

    //       <Route path="/register" element={<Register />} />

    //       <Route path="/exams" exact element={<ExamList />} />

    //       {/* </Route> */}

    //     </Routes>
    //   </Suspense>
    // </>
  );
}

export default App;
